import { ActivatedRoute, Params } from '@angular/router';
import { ApiConfiguration } from 'src/app/generated/mediazione/api-configuration';
import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload';
import { OnClickCallback } from 'ng-zorro-antd/modal';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss']
})
export class UploaderComponent implements OnInit {
  error = false;
  noSameFile = false;
  id: string;
  files: NzUploadFile[] = [];
  formdata: FormData = new FormData();

  @Input() allowMultiple = true;

  @Output() cancelId: EventEmitter<string> = new EventEmitter<string>();
  @Output() addId: EventEmitter<{
    idTemp: string;
    uidNz: string;
    fileName?: string;
  }> = new EventEmitter<{
    idTemp: string;
    uidNz: string;
    fileName?: string;
  }>();

  protected get allowUpload() {
    return this.files.length && !this.allowMultiple;
  }

  constructor(
    private apiConf: ApiConfiguration,
    private http: HttpClient,
    private utilsService: UtilsService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params: Params) => this.id = params['id']);
  }

  customRequest = (item: NzUploadXHRArgs) => {
    if (!this.allowMultiple) {
      this.files.map(ff => this.cancelId.emit(ff.uid));
      this.files = [];
      this.files.push(item.file);
    }
    this.formdata.append('files', item.file as any);
    this.formdata.append('idMediazione', this.id);
    let url_ = environment.be_url + this.utilsService.URL_UPLOAD;
    return this.http.post(url_, this.formdata).subscribe(res => {
      if (res) {
        const event = { percent: 100 };
        item.onSuccess(event, item.file, undefined);
        const list: string[] = res as string[];
        const splitedList: string[] = list[list.length - 1].split('/');
        const fileName: string = splitedList[splitedList.length - 1];
        this.addId.emit({
          idTemp: list[list.length - 1],
          uidNz: item.file.uid,
          fileName
        });
        this.error = false;
      }
    });
  };

  handleRemove = (toRemove: NzUploadFile) => {
    this.cancelId.emit(toRemove.uid);
    if (!this.allowMultiple) {
      this.files = this.files.filter(ff => ff.uid !== toRemove.uid);
      this.formdata.delete('files');
      this.formdata.delete('idMediazione');
    }
    console.log(' files **** ', this.files);
    return true;
  };

  beforeUpload = (item: NzUploadFile): boolean => {
    const foundSameName = this.files.filter(ff => ff.name === item.name);
    if (foundSameName && foundSameName.length > 0) {
      this.noSameFile = true;
      return false;
    } else {
      this.noSameFile = false;
    }
  };
}
