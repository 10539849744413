<div nz-row>
  <!-- <nz-form-item>
    <nz-form-control>
      <input required type="file" (change)="load($event)" multiple="false" #file>
    </nz-form-control>
  </nz-form-item>
  <span *ngIf="error" nz-typography nzType="danger">Selezionare un file</span> -->

  <nz-upload [nzFileList]="files" [nzCustomRequest]="customRequest.bind(this)" [nzBeforeUpload]="beforeUpload"
    [nzShowUploadList]="{ showDownloadIcon: false, showRemoveIcon: true }" [nzRemove]="handleRemove">
    <button nz-button [attr.disabled]="allowUpload || null">
      <span nz-icon nzType="upload"></span>
      Upload
    </button>
  </nz-upload>
  <span *ngIf="error" class="mb-3 ml-3" nz-typography nzType="danger">Selezionare un file</span>
  <span *ngIf="noSameFile" class="mt-3 ml-3" nz-typography nzType="danger">Non è possibile caricare file con lo stesso
    nome</span>
</div>
